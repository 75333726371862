<template>
    <div class="reportEatDeskOrderDetail defaultReport">
        <div class="content-box clearfix">
            <div class="panel-box top-info-box">
                <div class="flex1">
                    <span class="lable-text"><span class="name">营业日期：</span>{{data?.Rpt_Date}} </span>
                    <span class="lable-text"><span class="name">桌号：</span>{{data?.Eat_DeskName}} </span>
                    <span class="lable-text"><span class="name">开始起菜时间：</span>{{data?.StartCookName}} {{shortFormat(data?.BegCookTime)}} </span>
                    <span class="lable-text"><span class="name">上齐时间：</span>{{data?.EndCookName}} {{shortFormat(data?.EndCookTime)}} </span>
                    <span class="lable-text"><span class="name">标准时长:</span>{{data?.StandardTime}}/分 </span>
                    <span class="lable-text"><span class="name">理论上齐时间:</span>{{shortFormat(TheoryEndCookTime)}}</span>
                    <span class="lable-text"><span class="name">执行标准：</span>{{RunDeskFishTimeTriggerByLastCrossedTime?"按划菜时间计算":"按菜品上齐计算"}} </span>
                </div>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="nav-box-top">
                <div class="nav-box" v-mouse-scroll >
                    <div class="nav-li" :class="{selected:tagType==1}" @click="tagType=1">账单明细情况</div>
                    <div class="nav-li" :class="{selected:tagType==2}" @click="tagType=2">催起菜轨迹</div>
                </div>
            </div>
            <div class="tab-pane" v-show="tagType==1">
                <div class="table-box grey-table headerCol2" ref="tableBox">
                    <el-table class="el-table--scrollable-y"  :data="tableListPage" ref="elTable"
                        border 
                        :row-class-name="setClassName"
                        @cell-dblclick="orderDetail"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column fixed class-name="DeskStatus" prop="Rpt_Date" label="营业日期" min-width="90">
                            <template #default>
                                {{data?.Rpt_Date}}
                            </template>
                        </el-table-column>
                        <el-table-column fixed class-name="DeskStatus" prop="Eat_CheckIndex" align="left" label="结账单号" min-width="160"></el-table-column>
                        <el-table-column fixed class-name="DeskStatus" prop="Eat_DeskName" align="left" label="桌号" min-width="100"></el-table-column>
                        <el-table-column class-name="DeskStatus" prop="Eat_ManNum" label="人数" min-width="50" data-format="number"></el-table-column>
                        <el-table-column class-name="DeskStatus" prop="Eat_XFCode" label="菜品编码" min-width="80"></el-table-column>
                        <el-table-column class-name="DeskStatus" prop="Eat_XFName" label="菜品名称" min-width="80"></el-table-column>
                        <el-table-column prop="Eat_Number" label="下单数量" min-width="60" align="right" data-format="number"></el-table-column>
                        <el-table-column prop="Refund_Number" label="退菜数量" min-width="60" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link" v-if="scope.row.Refund_Number!=0">{{scope.row.Refund_Number}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Eat_XFSize" label="单位" min-width="50"></el-table-column>
                        <el-table-column prop="ISKDSNOSHOW" label="是否考核" min-width="75">
                            <template #header>
                                <el-tooltip  effect="dark" content="来源菜品设置项【KDS不显示】作为不考菜品" placement="top">
                                    <div class="tip">是否考核
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                            <template #default="scope">
                                <template v-if="scope.isExcel">
                                    {{scope.row.ISKDSNOSHOW?"否":"是"}}
                                </template>
                                <span class="kdsNoShow" v-else>
                                    <i class="iconfont icon-cha" v-if="scope.row.ISKDSNOSHOW"></i>
                                    <i class="iconfont icon-gou" v-else></i>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="上菜顺序">
                            <el-table-column prop="ServingOrder_InBook" label="理论" min-width="80" data-format="number"></el-table-column>
                            <el-table-column prop="ServingOrder_InReal" label="实际" min-width="80" data-format="number"></el-table-column>
                        </el-table-column>
                        <el-table-column prop="Food_level" label="菜品等级" min-width="60" data-format="number"></el-table-column>
                        <el-table-column label="系统标准值(单位:分)">
                            <el-table-column prop="KIT_LENGTH" label="标准时长" min-width="80" data-format="number">
                                <template #header>
                                    <el-tooltip  effect="dark" content="来源菜品设置项【制作标准时长】" placement="top">
                                        <div class="tip">标准时长
                                            <svg class="icon-svg" aria-hidden="true">
                                                <use xlink:href="#icon-infoFilled"></use>
                                            </svg>
                                        </div>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="EndCookTime" label="理论完成时间" min-width="100">
                                <template #header>
                                    <el-tooltip  effect="dark" content="按制作标准时长和菜品等级运算值" placement="top">
                                        <div class="tip">理论完成时间
                                            <svg class="icon-svg" aria-hidden="true">
                                                <use xlink:href="#icon-infoFilled"></use>
                                            </svg>
                                        </div>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="出菜(单位:分)">
                            <el-table-column prop="RealCookTime" label="出菜时长" min-width="80">
                                <template #header>
                                    <el-tooltip  effect="dark" content="划菜时间-起菜时间" placement="top">
                                        <div class="tip">出菜时长
                                            <svg class="icon-svg" aria-hidden="true">
                                                <use xlink:href="#icon-infoFilled"></use>
                                            </svg>
                                        </div>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="OverTime" label="超时时长" min-width="80">
                                <template #header>
                                    <el-tooltip  effect="dark" content="划菜时间-理论完成时间" placement="top">
                                        <div class="tip">超时时长
                                            <svg class="icon-svg" aria-hidden="true">
                                                <use xlink:href="#icon-infoFilled"></use>
                                            </svg>
                                        </div>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="TransferFoodTime" label="传菜时长(单位:分)" min-width="80">
                            <template #header>
                                <el-tooltip  effect="dark" content="上桌时间-划菜时间" placement="top">
                                    <div class="tip">传菜时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                        (单位:分)
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="前厅操作">
                            <el-table-column prop="Eat_Time" label="点菜" min-width="70" >
                                <template #default="scope">
                                    {{scope.row.Eat_Time}}
                                    <p class="small">{{scope.row.Eat_Czy}}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="CallUp_Time" label="起菜" min-width="70" >
                                <template #default="scope">
                                    {{scope.row.CallUp_Time}}
                                    <p class="small">{{scope.row.CallUp_Czy}}</p>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="后厨操作">
                            <el-table-column prop="Cut_Time" label="切配" min-width="70" >
                                <template #default="scope">
                                    {{scope.row.Cut_Time}}
                                    <p class="small">{{scope.row.Cut_Czy}}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Do_Time" label="制作" min-width="70" >
                                <template #default="scope">
                                    {{scope.row.Do_Time}}
                                    <p class="small">{{scope.row.Do_Czy}}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="End_Time" label="划菜" min-width="70" >
                                <template #default="scope">
                                    {{scope.row.End_Time}}
                                    <p class="small">{{scope.row.End_Czy}}</p>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="OnDesk_Time" label="上桌" min-width="70" >
                            <template #default="scope">
                                {{scope.row.OnDesk_Time}}
                                <p class="small">{{scope.row.OnDesk_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Food_Status" label="菜品状态" min-width="70" >
                            <template #default="scope">
                                {{["待切配","已切配 待制作","已制作 待划菜","已划菜 待上桌","已上桌"][scope.row.Food_Status]}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="Eat_MasName" label="要求" min-width="160" ></el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="tableList" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>tableListPage=list" 
                    :page-size="pagesize"></table-page-btn>
            </div>
            <div class="tab-pane" v-show="tagType==2">
                <div class="table-box grey-table" ref="tableBox2"   >
                    <el-table class="el-table--scrollable-y"  :data="tableListPage2"  border ref="elTable2" 
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column fixed label="序号" width="50" type="index">
                            <template #default="scope">{{scope.$index+1+(pagesize2*(currentPage2-1))}}</template>
                        </el-table-column>
                        <el-table-column prop="SpeedDesc" label="操作描述" min-width="120" align="left" ></el-table-column>
                        <el-table-column prop="Eat_Czy" label="操作人" min-width="100" align="left" > </el-table-column>
                        <el-table-column prop="UpdateTime" label="操作时间" min-width="120" :formatter="dateFormat"></el-table-column>
                        <el-table-column prop="PosName" label="操作设备名" min-width="100" align="left" ></el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="tableList2" 
                    :default-index="currentPage2" 
                    @current-change="(index)=>currentPage2=index"
                    @page-change="(list)=>tableListPage2=list" 
                    :page-size="pagesize2"></table-page-btn>
            </div>
        </div>
     </div>
</template>
<script>


/**按桌号计时账单明细表 */
export default {
    name:'reportEatDeskOrderDetail',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            //页签
            tagType:1,
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
          
            /**表格数据 明细数据 */ 
            currentPage2:1,
            tableList2:[],
            tableListPage2:[],
            pagesize2:9,//每页的数据条数
            //排序用
            sortable2:null
        }
    },
    computed:{
        /**是否 开启【桌台上齐完成时间，结合划菜完成时间计算】默认按上桌完成时间 */
        RunDeskFishTimeTriggerByLastCrossedTime(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting?.RunDeskFishTimeTriggerByLastCrossedTime;
        },
        //理论上齐时间
        TheoryEndCookTime(){
            if(!this.data?.BegCookTime || this.data?.BegCookTime=='0001-01-01T00:00:00'){
                return '';
            }
            if(this.data?.StandardTime>0){
                let time=new Date(this.data?.BegCookTime);
                time.setMinutes(time.getMinutes()+this.data.StandardTime);
                return time.Format('yyyy-MM-dd hh:mm:ss');
            }
            return "";
        }
    },
    watch:{
        tagType(){
            if(this.tagType==1){
                if(!(this.tableList?.length>0)){
                    this.GetBillCheck();
                }
            }else if(this.tagType==2){
                if(!(this.tableList2?.length>0)){
                    this.GetLogReport();
                }
            }
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.GetBillCheck();
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize2(){
            if(this.$refs.tableBox2){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox2.clientHeight - 80;
                    this.pagesize2=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.tagType==1){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"账单明细情况",
                    list:this.tableList,
                })
            }else{
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable2,
                    titleName:"催起菜轨迹",
                    list:this.tableList2,
                })
            }
        },
        //设置列类名
        setClassName({ row }){
            if(row.IsDeskOutTime){// 超时
                return "timeout";
            }
            return '';
        },
        /*时间格式 */
        shortFormat(cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('【MM/dd hh:mm:ss】');
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 催起菜轨迹 */
        GetLogReport(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date,//日期
                Eat_AutoID:this.data?.Eat_AutoID,//订单id
               
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.DeskTimeLogReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList2=d.ResponseBody;
                    console.log(d)
                    this.setTablePageSize2();
                    this.currentPage2=1;
                }else{
                    this.tableList2=[];
                    this.currentPage2=1;
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList2=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        /**账单明细情况 */
        GetBillCheck(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date,//日期
                Eat_AutoID:this.data?.Eat_AutoID,//订单id
                DeskEndTime:this.TheoryEndCookTime,//桌台标准上齐时间
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.FoodServingOrderDetailReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.currentPage=1
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="Refund_Number" && row.Refund_Number!=0){//穿透 退菜时间分析表
                this.$emit("addFrame",{
                    key:"backFood"+row.Eat_AutoID+row.Eat_XFBMID,
                    data:{
                        Rpt_Date:this.data?.Rpt_Date,
                        Eat_CheckIndex:row.Eat_CheckIndex,
                        Eat_DeskName:row.Eat_DeskName,
                        Eat_AutoID:row.Eat_AutoID,
                        Eat_XFBMID:row.Eat_XFBMID,
                        Eat_XFName:row.Eat_XFName
                    },
                    title:'退菜时间分析表',
                    url:'backFoodTimeReport'
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportEatDeskOrderDetail.scss'
</style>